<template>
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title">#{{ customer.id }} {{ customer.name }}</h1>
    </div>
    <div class="card-content">
      <b-tabs type="is-boxed">
        <b-tab-item label="Stammdaten" icon="contact_mail">
          <div class="columns is-6 view-columns">
            <div class="column">
              <label>Kundennummer</label>
              #{{ $utilities.padLeftWithNull(customer.id) }}
            </div>
            <div class="column">
              <label>Kundentyp</label>
              {{(customer.type != 'c') ? 'Privatkunde' : 'Firmenkunde'}} 
            </div>
            <div class="column">
              <label>Vorname</label>
              {{ customer.firstname }}
            </div>
            <div class="column">
              <label>Nachname</label>
              {{ customer.lastname }}
            </div>
          </div>

          <div class="columns view-columns is-6">
            <div class="column">
            <label>Firma</label>
              {{ customer.company }}
              <label>Anschrift</label>
              {{ customer.adress1 }} <br />
              <span
                v-if="customer.adress2 != null && customer.adress2.length > 2"
                >{{ customer.adress2 }} <br
              /></span>
              {{ customer.zip }} {{ customer.city }}<br />
              {{ customer.country.name_de }}
            </div>

            <div class="column">
              <label>Telefon / Mobile / E-Mail </label>
              <b-icon icon="phonelocal_phone"></b-icon>
              <a :href="'tel:' + customer.telephone">{{ customer.telephone }}</a
              ><br />
              <b-icon icon="mobile"></b-icon>
              <a :href="'tel:' + customer.mobile">{{ customer.mobile }}</a
              ><br />
              <b-icon icon="email2"></b-icon>
              <a :href="'mailto:' + customer.email">{{ customer.email }}</a
              ><br />

              Newsletter-Empfänger: {{ customer.newsletter == 1 ? "ja" : "nein"
              }}<br />
            </div>
          </div>
          <div class="columns view-columns is-6">
            <div class="column">
              <label>Aktiv / Sprache / VAT-ID</label>
              Darf sich anmelden: {{ customer.active == 1 ? "ja" : "nein"
              }}<br />

              Sprache: {{ customer.language }}<br />
            </div>
            <div class="column">
              <label>Bankverbindung</label>
              Bankeinzug: {{ customer.direct_debt == 1 ? "ja" : "nein" }}<br />
              Bank: {{ customer.bank_name }}<br />
              Konto: {{ customer.bank_account }}<br />
              BIC: {{ customer.bank_bic }}<br />
              VAT-ID: {{ customer.vat_id }}<br />
            </div>
          </div>

          <div class="columns view-columns">
            <div class="column">
              <label>Versandadresse</label>
              Separate Versandadresse:
              {{ customer.shipping_address == 1 ? "ja" : "nein" }}
              <div v-if="customer.shipping_address == 1">
                {{ customer.shipping_name }}<br />
                {{ customer.shipping_address1 }}<br />
                {{ customer.shipping_address2 }}<br />
                {{ customer.shipping_zip }} {{ customer.shipping_city }}<br />
                {{ customer.shippingcountry.name_de }}
              </div>
            </div>
          </div>
          <b-button
            class="button"
            type="is-info"
            @click="customerEdit(customer.id)"
            icon-left="square-edit-outline"
            >Bearbeiten</b-button
          >

          &nbsp;

          <b-button
            class="button"
            type="is-danger"
            icon-left="delete_forever"
            @click="customerDelete(customer.id, customer.name)"
            >Löschen</b-button
          >
        </b-tab-item>

        <!-- 
          ===================================================================
          Ankäufe
          ===================================================================
          -->
        <b-tab-item label="Ankäufe" icon="add_shopping_cart">
          <b-table
            :data="customer.consignments"
            :striped="true"
            :hoverable="true"
            icon-pack="icm"
            :mobile-cards="false"
            v-if="customer.consignments.length >= 1"
            :row-cell="(row, index) => getStatusColor(row.status_code)"
          >
            <b-table-column
              v-slot="props"
              field="id"
              label="Id"
              cell-class="status"
              numeric
              sortable
            >
              {{ props.row.id }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="fcreated_at"
              label="Angelegt"
              sortable
            >
              {{ props.row.fcreated_at }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="fsequence_created_at"
              label="Letzte Aktion"
            >
              {{ props.row.fsequence_created_at }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              label="A"
              class="iconlink"
              field="link1"
              header-class="iconlink"
            >
              <a
                title="Ansehen"
                @click="goTo(`/consignments/view/${props.row.id}`)"
              >
                <b-icon icon="find_in_page"></b-icon>
              </a>
            </b-table-column>
          </b-table>

          <p v-if="customer.consignments.length == 0">
            Für diesen Kunden/Lieferanten wurden keine Ankäufe erfasst.
          </p></b-tab-item
        >

        <!-- 
          ===================================================================
          Rechnungen
          ===================================================================
          -->
        <b-tab-item label="Rechnungen" icon="document-check">
          <b-table
            v-if="customer.invoices.length >= 1"
            :data="customer.invoices"
            :striped="true"
            :hoverable="true"
            icon-pack="icm"
            :mobile-cards="false"
            :row-cell="(row, index) => getStatusColor(row.status_code)"
          >
            <b-table-column
              v-slot="props"
              field="Invoice.doc_id"
              label="Id"
              cell-class="status"
              numeric
              sortable
            >
              {{ $utilities.padLeftWithNull(props.row.doc_id) }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="fcreated_at"
              label="Datum"
              numeric
              sortable
            >
              {{ props.row.fcreated_at }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="Invoice.total"
              label="Total"
              numeric
              sortable
            >
              {{ props.row.total }}€
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="Invoice.customer_id"
              label="Status"
              sortable
            >
              <b-icon
                icon="dollar"
                :class="{
                  green: props.row.paid > 1,
                  grey: props.row.paid == 0 || props.row.paid == null
                }"
                title="bezahlt"
                style="padding-right: 5px"
              >
              </b-icon
              ><b-icon
                icon="delivery"
                :class="{
                  green: props.row.delivered > 1,
                  grey: props.row.delivered == 0 || props.row.delivered == null
                }"
                title="versandt"
              ></b-icon>
            </b-table-column>

            <b-table-column
              v-slot="props"
              label="Ans."
              class="iconlink"
              header-class="iconlink"
            >
              <a
                @click="goTo(`/invoices/view/${props.row.id}`)"
                title="Ansehen"
              >
                <b-icon icon="find_in_page"></b-icon>
              </a>
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="RE"
              class="iconlink"
              header-class="iconlink"
            >
              <a
                title="Rechnung (PDF)"
                @click.prevent="downloadInvoicePDF(props.row.id)"
              >
                <b-icon icon="file-pdf"></b-icon>
              </a>
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="LS"
              class="iconlink"
              header-class="iconlink"
            >
              <a
                v-if="props.row.sum_auctions >= 1"
                title="Lieferschein (PDF)"
                @click.prevent="downloadDeliveryPDF(props.row.id)"
              >
                <b-icon icon="package-variant-closed"></b-icon>
              </a>
            </b-table-column>
          </b-table>
          <p v-if="customer.invoices.length == 0">
            Für diesen Kunden wurden keine Rechnungen erstellt.
          </p>
        </b-tab-item>

        <!-- 
          ===================================================================
          Notizen
          ===================================================================
          -->
        <b-tab-item label="Notizen/Wiedervorlagen" icon="date_range">
          <b-button
            icon-left="plus1"
            type="is-info"
            class="button"
            v-show="!noticeFormVisible"
            @click="toggleNoticeFormVisible()"
            >Neue Notiz/Wiedervorlage</b-button
          >
          <form
            method="post"
            @submit.prevent="saveNotice"
            v-show="noticeFormVisible"
            class="smallform"
          >
            <label>Notiz</label>
            <b-input
              v-model.trim="$v.notice.notice.$model"
              :class="{ is_danger: $v.notice.notice.$error }"
              type="textarea"
            >
            </b-input>
            <div class="is-error" v-if="submitted && $v.notice.notice.$error">
              Bitte geben Sie einen Text ein.
            </div>

            <label>Wiedervorlagedatum (optional)</label>
            <b-datepicker
              v-model.trim="$v.notice.reminderdate.$model"
              :class="{ is_danger: $v.notice.reminderdate.$error }"
              icon="date_range"
              :month-names="[
                'Januar',
                'Februar',
                'März',
                'April',
                'Mai',
                'Juni',
                'Juli',
                'August',
                'September',
                'Oktober',
                'November',
                'Dezember'
              ]"
              :day-names="['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']"
              :first-day-of-week="1"
            >
              trap-focus>
            </b-datepicker>

            <div
              class="is-error"
              v-if="submitted && $v.notice.reminderdate.$error"
            >
              Bitte geben Sie gültiges Datum an.
            </div>

            <button
              class="button"
              type="button"
              @click="toggleNoticeFormVisible()"
            >
              Schließen
            </button>
            &nbsp;

            <b-button
              class="button"
              type="is-info"
              native-type="submit"
              icon-left="cloud_upload"
              >Speichern</b-button
            >
          </form>
         
        </b-tab-item>
      </b-tabs>
    </div>
    <div class="card-footer">
      <div class="column">
        <button class="button" type="button" @click="$parent.close()">
          Schließen
        </button>
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators"
export default {
  name: "customerView",
  props: ["pcustomer"],
  data: function () {
    return {
      noticeFormVisible: false,
      notice: { reminderdate: null, notice: "" },
      submitted: false,
      error: false,
      customer: this.pcustomer
    }
  },

  validations: {
    notice: {
      notice: {
        required,
        minLength: minLength(3)
      },
      reminderdate: {}
    }
  },

  methods: {
    customerDelete: function (id, name) {
      this.$parent.close()
      this.$parent.$parent.customerDelete(id, name)
    },
    customerEdit: function (id) {
      this.$parent.close()
      this.$parent.$parent.customerEdit(id)
    },
    toggleNoticeFormVisible() {
      //console.log('Form: ' + this.noticeFormVisible)
      this.noticeFormVisible = !this.noticeFormVisible
    },
    saveNotice() {
      this.error = null
      this.submitted = true
      this.$v.$touch()
      var s = this
      if (s.$v.$invalid) {
        //console.log('Fehler in Form')
      } else {
        s.$http
          .post("/customers/save-customer-notice", {
            notice: s.notice.notice,
            reminderdate: s.$utilities.dateFormatToIso(s.notice.reminderdate),
            customer_id: s.customer.id
          })
          .then(function (resp) {
            s.isLoading = false
            s.customer.notices = resp.data.data
            ;(s.noticeFormVisible = false), (s.submitted = false)
            s.error = false

            return true
          })
          .catch(function (error) {
            //console.log('Shithappens')
            if (
              typeof error.response != "undefined" &&
              typeof error.response.data.message != "undefined"
            ) {
              s.error = error.response.data.message
            } else {
              s.error =
                "Server nicht erreichbar. Bitte versuchen Sie es erneut."
            }
            s.isLoading = false
            return false
          })
        return false
      }
    },
    deleteNotice: function (id, ref_id) {
      //console.log('lösche ' + id + ' und ' + ref_id)
      this.$buefy.dialog.confirm({
        title: "Bitte bestätigen!",
        message: "Wollen Sie Notiz #" + id + " wirklich löschen?",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.noticeDelete2(id, ref_id)
      })
    },

    noticeDelete2: function (id, ref_id) {
      //console.log('delete '+id);
      var t = this
      this.$http({
        url: "/customers/delete-customer-notice/" + id + "/" + ref_id,
        method: "get"
      })
        .then(function (resp) {
          //console.log(id + ' erfolgreich gelsöcht')
          t.customer.notices = resp.data.data
          return true
        })
        .catch(function (error) {
          //console.log('Shithappens')
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.$buefy.toast.open({
              message: error.response.data.message,
              type: "is-danger",
              duration: 5000
            })
          } else {
            t.$buefy.toast.open({
              message:
                "Server (API) nicht erreichbar. Bitte versuchen Sie es später erneut.",
              type: "is-danger",
              duration: 5000
            })
          }
        })
    },
    setNoticeReminderDone: function (id, ref_id) {
      //console.log('delete '+id);
      var t = this
      this.$http({
        url: "/customers/notice-reminder-done/" + id + "/" + ref_id,
        method: "get"
      })
        .then(function (resp) {
          t.customer.notices = resp.data.data
          return true
        })
        .catch(function (error) {
          //console.log('Shithappens')
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.$buefy.toast.open({
              message: error.response.data.message,
              type: "is-danger",
              duration: 5000
            })
          } else {
            t.$buefy.toast.open({
              message:
                "Server (API) nicht erreichbar. Bitte versuchen Sie es später erneut.",
              type: "is-danger",
              duration: 5000
            })
          }
        })
    },
    goTo: function (url) {
      this.$parent.close()
      this.$router.push(url)
    },
    getStatusColor: function (code) {
      switch (code) {
        case "9":
          return "red"
        case "4":
          return "green"
        case "3":
          return "lightgreen"
        case "2":
          return "blue"
        case "1":
          return "yellow"
        default:
          return ""
      }
    },
    downloadInvoicePDF: function (id) {
      var url =
        this.$store.getters.config.page.apiBaseUrl +
        "/invoices/pdf-invoice/" +
        id
      this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" })
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = "invoice_" + id + ".pdf"
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    },

    downloadDeliveryPDF: function (id) {
      var url =
        this.$store.getters.config.page.apiBaseUrl +
        "/invoices/pdf-delivery/" +
        id
      this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" })
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = "delivery_" + id + ".pdf"
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    },
    getStatusColor: function(code) {
      switch (code) {
        case "9":
          return "red"
        case "4":
          return "green"
        case "3":
          return "lightgreen"
        case "2":
          return "blue"
        case "1":
          return "yellow"
        default:
          return ""

        /*

        (case 
        when ((`delivered` = 1) and (`sold` = 0)) then 9 
        when ((`delivered` = 1) and (`sold` = 1)) then 4 
        when ((`delivered` = 0) and (`sold` = 1)) then 3 
        when ((`delivered` = 0) and (`sold` = 0) and (`auction_id` >= 1)) then 2 
        when ((`delivered` = 0) and (`sold` = 0) and (`auction_id` = 0)) then 1 
        else 0 end)
        */
      }
    }
  }
}
</script>

<template>
  <div class="card">
    <form method="post" @submit.prevent="saveCustomer">
      <div class="card-header">
        <h1 class="card-header-title">{{ typeOfAction }}</h1>
      </div>
      <div class="card-content">
        <div class="columns is-6 view-columns">
          <div class="column">
            <b-field label="Kundennummer">
              #{{ $utilities.padLeftWithNull(customer.id) }}
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="Kundentyp"
              :type="{ 'is-danger': $v.customer.type.$error }"
              :message="{
                'Bitte geben Sie eine gültige E-Mailadresse ein.':
                  submitted && $v.customer.type.$error
              }"
            >
              <b-switch
                v-model="$v.customer.type.$model"
                true-value="c"
                false-value="p"
                size="is-small"
                @input="changeType"
              >
              </b-switch>
              {{($v.customer.type.$model != 'c') ? 'Privatkunde' : 'Firmenkunde'}} 
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="Vorname"
              :type="{ 'is-danger': $v.customer.firstname.$error }"
              :message="{
                'Bitte geben Sie einen Vornamen ein.':
                  submitted && $v.customer.firstname.$error
              }"
            >
              <b-input
                v-model.trim="$v.customer.firstname.$model"
                :class="{ is_danger: $v.customer.firstname.$error }"
              >
              </b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="Nachname"
              :type="{ 'is-danger': $v.customer.lastname.$error }"
              :message="{
                'Bitte geben Sie einen Nachnamen ein.':
                  submitted && $v.customer.lastname.$error
              }"
            >
              <b-input
                v-model.trim="$v.customer.lastname.$model"
                :class="{ is_danger: $v.customer.lastname.$error }"
              >
              </b-input>
            </b-field>
          </div>
        </div>

        <div class="columns view-columns is-6">
          <div class="column">
          <b-field
         
              label="Firma"
              :type="{ 'is-danger': $v.customer.company.$error }"
              :message="{
                'Bitte geben Sie eine Firma an.':
                  submitted && $v.customer.company.$error
              }"
            >
              <b-input
               :disabled="($v.customer.type.$model != 'c') ? 'disabled': false"
                v-model.trim="$v.customer.company.$model"
                :class="{ is_danger: $v.customer.company.$error }"
              >
              </b-input>
            </b-field>
            <b-field
              label="Anschrift"
              :type="{ 'is-danger': $v.customer.adress1.$error }"
              :message="{
                'Bitte geben Sie eine Adresse ein.':
                  submitted && $v.customer.adress1.$error
              }"
            >
              <b-input
                v-model.trim="$v.customer.adress1.$model"
                :class="{ is_danger: $v.customer.adress1.$error }"
                placeholder="Adresszeile 1"
              >
              </b-input>
            </b-field>

            <b-field
              label="Anschrift 2.Zeile (optional)"
              :type="{ 'is-danger': $v.customer.adress2.$error }"
              :message="{
                'Bitte geben Sie eine Adresse ein.':
                  submitted && $v.customer.adress2.$error
              }"
            >
              <b-input
                v-model.trim="$v.customer.adress2.$model"
                :class="{ is_danger: $v.customer.adress2.$error }"
                placeholder="Adresszeile 2"
              >
              </b-input>
            </b-field>

            <b-field
              label="Postleitzahl"
              :type="{ 'is-danger': $v.customer.zip.$error }"
              :message="{
                'Bitte geben Sie eine PLZ ein.':
                  submitted && $v.customer.zip.$error
              }"
            >
              <b-input
                v-model.trim="$v.customer.zip.$model"
                :class="{ is_danger: $v.customer.zip.$error }"
                placeholder="PLZ"
              >
              </b-input>
            </b-field>

            <b-field
              label="Ort"
              :type="{ 'is-danger': $v.customer.city.$error }"
              :message="{
                'Bitte geben Sie einen Ort ein.':
                  submitted && $v.customer.city.$error
              }"
            >
              <b-input
                v-model.trim="$v.customer.city.$model"
                :class="{ is_danger: $v.customer.city.$error }"
                placeholder="Ort"
              >
              </b-input>
            </b-field>
            <b-field
              label="Land"
              :type="{ 'is-danger': $v.customer.country_id.$error }"
              :message="{
                'Bitte wählen Sie ein Land.':
                  submitted && $v.customer.country_id.$error
              }"
            >
              <b-select
                placeholder="Bitte wählen Sie ein Land"
                v-model.trim="$v.customer.country_id.$model"
                :class="{ is_danger: $v.customer.country_id.$error }"
                style="width:inherit"
              >
                <option
                  v-for="option in countryList"
                  :value="option.id"
                  :key="option.id"
                >
                  {{ option.name_de }}
                </option>
              </b-select>
            </b-field>
          </div>

          <div class="column">
            <b-field
              label="Telefon"
              :type="{ 'is-danger': $v.customer.telephone.$error }"
              :message="{
                'Bitte geben Sie eine Telefonnummer ein.':
                  submitted && $v.customer.telephone.$error
              }"
            >
              <b-input
                v-model.trim="$v.customer.telephone.$model"
                :class="{ is_danger: $v.customer.telephone.$error }"
                placeholder="Telefon"
                icon="phonelocal_phone"
              >
              </b-input>
            </b-field>

            <b-field
              label="Mobilnummer"
              :type="{ 'is-danger': $v.customer.mobile.$error }"
              :message="{
                'Bitte geben Sie eine Telefonnummer ein.':
                  submitted && $v.customer.mobile.$error
              }"
            >
              <b-input
                v-model.trim="$v.customer.mobile.$model"
                :class="{ is_danger: $v.customer.mobile.$error }"
                placeholder="Mobilnummer"
                icon="mobile"
              >
              </b-input>
            </b-field>

            <b-field
              label="E-Mail"
              :type="{ 'is-danger': $v.customer.email.$error }"
              :message="{
                'Bitte geben Sie eine gültige E-Mailadresse ein.':
                  submitted && $v.customer.email.$error
              }"
            >
              <b-input
                v-model.trim="$v.customer.email.$model"
                :class="{ is_danger: $v.customer.email.$error }"
                placeholder="E-Mail"
                icon="email2"
              >
              </b-input>
            </b-field>

            <b-field
              label="Newsletter"
              :type="{ 'is-danger': $v.customer.newsletter.$error }"
              :message="{
                'Bitte geben Sie eine gültige E-Mailadresse ein.':
                  submitted && $v.customer.newsletter.$error
              }"
            >
              <b-switch
                v-model="$v.customer.newsletter.$model"
                true-value="1"
                false-value="0"
                size="is-small"
              >
              </b-switch>
              Newsletter-Empfänger
            </b-field>
          </div>
        </div>
        <div class="columns view-columns is-6">
          <div class="column">
            <b-field
              label="Aktiv"
              :type="{ 'is-danger': $v.customer.active.$error }"
              :message="{
                '': submitted && $v.customer.active.$error
              }"
            >
              <b-switch
                v-model.trim="$v.customer.active.$model"
                true-value="1"
                false-value="0"
                size="is-small"
              >
              </b-switch>
              Darf sich anmelden
            </b-field>
            
            <b-field
              label="Sprache"
              :type="{ 'is-danger': $v.customer.language.$error }"
              :message="{
                'Bitte wählen Sie die Sprache.':
                  submitted && $v.customer.language.$error
              }"
            >
              <div>
                <b-radio
                  v-model="$v.customer.language.$model"
                  name="language"
                  native-value="de"
                  default
                >
                  deutsch
                </b-radio>
                <b-radio
                  v-model="$v.customer.language.$model"
                  name="language"
                  native-value="en"
                >
                  english
                </b-radio>
              </div>
            </b-field>
            
          </div>
          <div class="column">
            <b-field
              label="Bankverbindung"
              :type="{ 'is-danger': $v.customer.direct_debt.$error }"
              :message="{
                'Bitte geben Sie einen Vornamen ein.':
                  submitted && $v.customer.direct_debt.$error
              }"
            >
              <b-switch
                v-model="$v.customer.direct_debt.$model"
                true-value="1"
                false-value="0"
                size="is-small"
              >
              </b-switch
              >Bankeinzug
            </b-field>
            <b-field
              label="Name der Bank"
              :type="{ 'is-danger': $v.customer.bank_name.$error }"
              :message="{
                'Bitte geben Sie den Namen der Bank ein.':
                  submitted && $v.customer.bank_name.$error
              }"
            >
              <b-input
                v-model.trim="$v.customer.bank_name.$model"
                :class="{ is_danger: $v.customer.bank_name.$error }"
                placeholder="Name der Bank"
              >
              </b-input>
            </b-field>
            <b-field
              label="IBAN/Kontonummer"
              :type="{ 'is-danger': $v.customer.bank_account.$error }"
              :message="{
                'Bitte geben Sie Kontonummer/IBAN ein.':
                  submitted && $v.customer.bank_account.$error
              }"
            >
              <b-input
                v-model.trim="$v.customer.bank_account.$model"
                :class="{ is_danger: $v.customer.bank_account.$error }"
                placeholder="IBAN/Kontonummer"
              >
              </b-input>
            </b-field>
            <b-field
              label="Bitte geben Sie BLZ/BIC ein."
              :type="{ 'is-danger': $v.customer.bank_bic.$error }"
              :message="{
                'Bitte geben Sie die BLZ/BIC ein.':
                  submitted && $v.customer.bank_bic.$error
              }"
            >
              <b-input
                v-model.trim="$v.customer.bank_bic.$model"
                :class="{ is_danger: $v.customer.bank_bic.$error }"
                placeholder="BLZ/BIC"
              >
              </b-input>
            </b-field>
            <b-field
              label="VAT-ID"
              :type="{ 'is-danger': $v.customer.vat_id.$error }"
              :message="{
                'Bitte geben Sie eine gültige VAT-ID ein.':
                  submitted && $v.customer.vat_id.$error
              }"
            >
              <b-input
               :disabled="($v.customer.type.$model != 'c') ? 'disabled': false"
                v-model.trim="$v.customer.vat_id.$model"
                :class="{ is_danger: $v.customer.vat_id.$error }"
                placeholder="VAT-ID"
              >
              </b-input>
            </b-field>
           
          </div>
        </div>
        <div class="columns view-columns">
          <div class="column">
            <b-field
              label="Separate Lieferadresse"
              :type="{ 'is-danger': $v.customer.shipping_address.$error }"
              :message="{
                'Bitte geben Sie eine gültige E-Mailadresse ein.':
                  submitted && $v.customer.shipping_address.$error
              }"
            >
              <b-switch
                v-model="$v.customer.shipping_address.$model"
                true-value="1"
                false-value="0"
                size="is-small"
              >
              </b-switch>
              Separate Lieferadresse
            </b-field>

            <!-- Versandadresse -->
            <div v-if="customer.shipping_address == 1" class="columns">
              <div class="column is-6">
                <b-field
                  label="Name/Firmenname"
                  :type="{ 'is-danger': $v.customer.shipping_name.$error }"
                  :message="{
                    'Bitte geben Sie einen Nachnamen ein.':
                      submitted && $v.customer.shipping_name.$error
                  }"
                >
                  <b-input
                    v-model.trim="$v.customer.shipping_name.$model"
                    :class="{ is_danger: $v.customer.shipping_name.$error }"
                  >
                  </b-input>
                </b-field>

                <b-field
                  label="Anschrift"
                  :type="{ 'is-danger': $v.customer.shipping_address1.$error }"
                  :message="{
                    'Bitte geben Sie einen Nachnamen ein.':
                      submitted && $v.customer.shipping_address1.$error
                  }"
                >
                  <b-input
                    v-model.trim="$v.customer.shipping_address1.$model"
                    :class="{ is_danger: $v.customer.shipping_address1.$error }"
                  >
                  </b-input>
                </b-field>

                <b-field
                  label="Anschrift 2. Zeile (optional)"
                  :type="{ 'is-danger': $v.customer.shipping_address2.$error }"
                  :message="{
                    'Bitte geben Sie einen Nachnamen ein.':
                      submitted && $v.customer.shipping_address2.$error
                  }"
                >
                  <b-input
                    v-model.trim="$v.customer.shipping_address2.$model"
                    :class="{ is_danger: $v.customer.shipping_address2.$error }"
                  >
                  </b-input>
                </b-field>
              </div>
              <div class="column is-6">
                <b-field
                  label="Postleitzahl"
                  :type="{ 'is-danger': $v.customer.shipping_zip.$error }"
                  :message="{
                    'Bitte geben Sie einen Nachnamen ein.':
                      submitted && $v.customer.shipping_zip.$error
                  }"
                >
                  <b-input
                    v-model.trim="$v.customer.shipping_zip.$model"
                    :class="{ is_danger: $v.customer.shipping_zip.$error }"
                  >
                  </b-input>
                </b-field>

                <b-field
                  label="Ort"
                  :type="{ 'is-danger': $v.customer.shipping_city.$error }"
                  :message="{
                    'Bitte geben Sie einen Nachnamen ein.':
                      submitted && $v.customer.shipping_city.$error
                  }"
                >
                  <b-input
                    v-model.trim="$v.customer.shipping_city.$model"
                    :class="{ is_danger: $v.customer.shipping_city.$error }"
                  >
                  </b-input>
                </b-field>

                <b-field
                  label="Land"
                  :type="{
                    'is-danger': $v.customer.shipping_country_id.$error
                  }"
                  :message="{
                    'Bitte wählen Sie ein Land.':
                      submitted && $v.customer.shipping_country_id.$error
                  }"
                >
                  <b-select
                    placeholder="Bitte wählen Sie ein Land"
                    v-model.trim="$v.customer.shipping_country_id.$model"
                    :class="{
                      is_danger: $v.customer.shipping_country_id.$error
                    }"
                    style="width:inherit"
                  >
                    <option
                      v-for="option in countryList"
                      :value="option.id"
                      :key="option.id"
                    >
                      {{ option.name_de }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="column">
          <b-button class="button" type="button" @click="$parent.close()"
            >Schließen</b-button
          >
          &nbsp;

          <b-button
            class="button"
            type="is-info"
            native-type="submit"
            icon-left="cloud_upload"
            >Speichern</b-button
          >
        </div>
      </div>
    </form>
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import {
  required,
  minLength,
  email,
  minValue,
  maxValue,
  numeric
} from "vuelidate/lib/validators"
const valTelephone = (value) => /^\+[0-9]{6,20}$/.test(value)
export default {
  name: "customerForm",
  props: ["pcustomer", "countryList"],

  data() {
    return {
      customer: this.pcustomer,
      error: null,
      isLoading: false,
      submitted: false,
      selectFee: this.selectFeeRange()
    }
  },

  computed: {
    typeOfAction: function() {
      if (typeof this.customer.id == "undefined" || this.customer.id == null) {
        return "Neuen Kunden anlegen"
      }
      return (
        "Kunden bearbeiten: #" + this.customer.id + " " + this.customer.name
      )
    }
  },
  validations: {
    customer: {
      company: {
        minLength: minLength(3)
      },
      firstname: {
        required,
        minLength: minLength(2)
      },
      lastname: {
        required,
        minLength: minLength(2)
      },
      adress1: {
        required,
        minLength: minLength(2)
      },
      adress2: {
        minLength: minLength(2)
      },
      zip: {
        required,
        minLength: minLength(3)
      },
      city: {
        required,
        minLength: minLength(2)
      },
      country_id: {},
      consignment_fee: {},

      email: {
        required,
        email
      },
      telephone: {},
      mobile: {},
      vat_id: {},
      bank_name: {},
      bank_account: {},
      bank_bic: {},
      active: { required },
      language: { required, minLength: minLength(1) },
      direct_debt: {},
      newsletter: {},
      shipping_address: { required },
      shipping_name: {},
      shipping_address1: {},
      shipping_address2: {},
      shipping_zip: {},
      shipping_city: {},
      shipping_country_id: {},
      type: { required }
    }
  },

  methods: {
    saveCustomer: function() {
      //console.log('customer-daten');
      //console.log(this.customer)
      this.error = null
      this.submitted = true
      this.$v.$touch()
      var s = this
      if (s.$v.$invalid) {
        //console.log('Fehler in Form')
      } else {
        s.$parent.$parent.customerSave(s.customer)
      }
    },
    selectFeeRange: function() {
      var sel = []
      for (var i = 0; i <= 25; i++) {
        sel.push({ id: i, name: i + " Prozent" })
      }
      return sel
    }
  }
}
</script>
<style>
.b-radio.radio span.control-label {
  font-size: 1rem;
  color: #4a4a4a;
}
</style>
